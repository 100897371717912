<template>
  <Company/>
</template>

<script>

import Company from './company/companyPage.vue'
import i18n from '@/i18n'

    export default {
        middleware: ['auth','vendor','is-vendor-active'],
        components:{
            Company
        },
      metaInfo() {
        return {
          title: i18n.t('my-company'),
          meta:[
            {
              name: 'description',
              content: i18n.t('description.vendors/my-company'),
            },
          ]
        }
      },
        data(){
            return{
                activePage: 0,
                loading: false,
                errored: false
            }
        },

        mounted()
        {

        },
        watch: {

                activePage: function(){
                     console.log(this.activePage);
                    // if (this.activePage == "tab3" ) this.loadPersonalized = !this.loadPersonalized;
                     //if (this.activePage == "tab2" ) this.fetchAddons();

                },

            },
        computed: {

        },
        methods: {


        }
    }
</script>
